@import url(https://use.typekit.net/tah1eyj.css);
/*****  000 Reset & Imports *****/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    translate: 0;
}

html {
  font-size: 24px;
}

/*****  00 Design/Brand Guide Sections *****/

h1, h2, h3, h5, h6 {
  font-family: reross-quadratic, sans-serif;
  line-height: 100%;
  margin-bottom: 2%
}

h4 {
  font-family: futura-pt, sans-serif;
  font-style: italic;
}


p {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  margin-bottom: 2em;
  font-size: 1em;
}

a {
  color: #F5F5F5; /* White Sand */
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.5s;
}


.a-alt a:hover p{
  background-color:  #F5F5F5; /* White Sand */
  color:  #363636;
  transition-duration: 0.5s;
}

.a-alt a {
  transition-duration: 1s;
}

.a-alt a:hover {
  transition-duration: 0.5s;
}

.btn.a-alt {
  color:  #F3515E;
  transition-duration:r 0.5s;
}


.a-alt2 {


  transition-duration: 0.5s;
}

.a-alt2:hover {
  -webkit-transform: translate3d(5px,-5px,0px);
          transform: translate3d(5px,-5px,0px);
  box-shadow: -5px 5px#363636; /* Mine Shaft */
  /* background-color: #F3515E; */
  transition-duration: 0.5s;
}

.a-alt2 a:hover {
  color: #F5F5F5; /* White Sand */

}

.a-alt3 a{
  color: #363636; /* Mine Shaft */
  -webkit-transform: translate3d(0px,0px,0px);
          transform: translate3d(0px,0px,0px);
  transition-duration: 0.5s;
  width: 150px;
}

.a-alt3 a:hover{
  background-color: #363636; /* Mine Shaft */
  border-radius: 5px;
  transition-duration: 0.5s;
  width: 150px;
  -webkit-transform: translate3d(5px,-5px,0px);
          transform: translate3d(5px,-5px,0px);
  box-shadow: -5px 5px#363636; /* Mine Shaft */
  /* background-color: #F3515E; */
}

.a-alt3.splash-img a p{
  color: #F5F5F5; /* White Sand */
  padding: .5rem;
}

.past-work h5 {
  margin: 1.5em 0;
}

.btn:hover {
  background-color: #04071A;
  transition-duration: 0.5s;
}

iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}

.iframeDiv {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

input {
  font-family: "futura-pt", serif;
  font-size: 1em;
}

li {
  list-style-type: none;
}

.font-1 {
  font-family: futura-pt, sans-serif;
  font-style: normal;
}

.font-2 {
  font-family: 'Lora', serif;
}

h1, h2 {
  font-weight: 700;
}

h3, h4, h5, h6 {
  font-weight: 500;
}

/*** selected fonts based on a Cadd9 chord using 4 notes,  ***/
h1 {
  font-size: 2.083rem; /* ratio is perfect fifth from next lowest */
}

h2 {
  font-size: 1.563rem; /* ratio is major 3rd from next lowest */
}

h3 {
  font-size: 1.25rem; /* ratio is major 3rd from next lowest */
}

h4 {
  font-size: 1rem; /* base of "chord" */
}

h5 {
  font-size: 0.75rem; /* ratio is perfect 4th smaller from base */
}

h6 {
  font-size: 0.6rem; /* ratio is major 3rd smaller from h5 */
}

.non-em {
  font-weight: 400;
}

.p-2 {
  font-size: .75rem; /* ratio is perfect 4th smaller from base */
}

.light {
  font-weight: 300;
}

hr {
  border: thin dotted #F5F5F5; /* ratio is major 2nd smaller from h6 */
  width: 80%;
  margin: 0 auto;
}

footer {
  position: relative;
  bottom: 100%;
  padding: 3em 5%;
  margin: 0 auto;
  max-width: 100%;
  border-radius: 3px 3px 0px 0px;
  text-align: left;
}

.swatch-1 {
  background-color: #F5F5F5; /* White Sand */
  color:  #363636; /* Mine Shaft */
}

.swatch-2 {
  background-color: #363636; /* Mine Shaft */
  color: #F5F5F5; /* White Sand */
}

.swatch-3 {
  background-color: #24B29B; /* Jungle Green */
  color: #F5F5F5; /* White Sand */
}

.swatch-3-alt {
  color: #24B29B; /* Jungle Green */
}

.swatch-4 {
  background-color: #FF9E19; /* Tree Poppy */
  color: #F5F5F5; /* White Sand */
}

.swatch-4-alt {
  color: #FF9E19; /* Tree Poppy */
}

.swatch-5 {
  background-color: #FF4D51; /* Sunset Orange */
  color: #F5F5F5; /* White Sand */
}

.swatch-5-alt {
  color: #FF4D51; /* Sunset Orange */
}

.swatch-6 {
  background-color: #B32D30; /* Tall Poppy */
  color: #F5F5F5; /* White Sand */
}

.swatch-6-alt  {
  color: #B32D30; /* Tall Poppy */
}

.swatch-7 {
  background-color: #B136B3; /* Vivid Violet */
  color: #F5F5F5; /* White Sand */
}

.swatch-7-alt  {
  color: #B136B3; /* Vivid Violet */
}

.swatch-8 {
  background-color: #1799E5; /* Curious Blue */
  color: #F5F5F5; /* White Sand */ 
}

.swatch-8-alt  {
  color: #1799E5; /* Curious Blue */
}

.color-swatch.swatch-1 {
  border-color: #363636; /* Mine Shaft */
}

.main-section.design-guide-section {
  padding: 2em 0px 2em 0px;
  text-align: left;
}

.main-section {
  border-radius: 0px 0px;
}

.main-section.odd {
  border-radius: 0px 0px;
}

.intro {
  padding: 2em 2% 2em 2%;
  text-align: left;
  border-radius: 100px 5px;
}

.box-set{
  max-width: 100%;
  -webkit-align-content: center;
          align-content: center;
  margin: 0 auto;
  padding: 2rem 2% 2rem 2%;
  overflow: hidden;
}

/*****  01 Main Elements  *****/

.main-nav {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  padding: 0% 5%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: stretch;
          align-content: stretch;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}


/*main-nav {
  max-width: 1280px;
  margin: 10% 10%;
} */

.main-nav-bar {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  width: 100%;
  padding: 1rem 5%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.main-nav-bar .header-img {
  justify-self: self-start;
  width: 30%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-align-items: center;
          align-items: center;
}

.header-img img {
  margin: .4em 0 0 0;
  width: 100%;
  -webkit-flex-shrink: 2;
          flex-shrink: 2;
}

.main-nav-bar nav {
  display: -webkit-inline-flex;
  display: inline-flex;
  justify-items: right;
  min-width: 40%;
  margin-left: auto;
  margin-right: 0px;
}

.main-nav-bar .header-nav {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-left: auto;
  margin-right: 0px;
  -webkit-flex-shrink: 3;
          flex-shrink: 3;
}

.main-nav-bar .header-nav li{
  width: 100%;
  text-align: right;
  margin-left: auto;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.header-nav .nav-item {
  -webkit-flex-shrink: 2;
          flex-shrink: 2;
  text-align: right;
  padding: .5rem 1rem;
  cursor: pointer;
  margin-right: 0%;
  margin-left: auto;
}

.header-nav .nav-item.first {
  padding-top: 0rem;
}

.header-img {
  min-width: 50px;
  -webkit-flex-basis: 200px;
          flex-basis: 200px;
  -webkit-flex-grow: 2;
          flex-grow: 2;
  overflow: visible;
}

/*.header-img:hover {
  /*background-color: teal;
} */
/*
.main-nav nav {
  background-color: #554546;*
  display: inline-flex;
  justify-content: space-around;

}

header.main-nav nav {
  display: inline-flex;
  overflow: visible;
  /*background-color: brown;
}*/

.header-nav {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  overflow: visible;
  -webkit-flex-shrink: 6;
          flex-shrink: 6;
  /*background-color: tomato;*/

}

.header-nav li {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-shrink: 5;
          flex-shrink: 5;
  /*background-color: rgba(37, 165, 122, 0.3);*/
  overflow: visible;
  margin: 0px 0px;
}

.header-nav a {
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  /*background-color: rgba(215, 13, 242, 0.5);*/
}

.h3 {
  font-size: 1.25rem; /* ratio is major 3rd from next lowest */
  font-family: "Quicksand", sans-serif; 
}

.foot-location {
  width: 40%;
  min-width: 300px;
  padding: 0 15% 0% 0%;
  display: inline-block;
  vertical-align: top;
  
}

.foot-contact {
  padding: 0 0% 0% 0%;
  width: 40%;
  margin-right: 15%;
  min-width: 300px;
  display: inline-block;
  vertical-align: top;
}

.foot-social {
  padding: 1rem 0% 0% 0%;
  width: 10%;
  min-width: 50px;
  display: inline-block;
  vertical-align: top;
}

.foot-social img {
  max-width: 50px;
}

.copyright {
  text-align: center;
}

.btn {
  background-color: #363636; /* Mine Shaft */;
  color: #F5F5F5; /* White Sand */;
  padding: 10px 20px;
  -webkit-transform: translate3d(0px,0px,0px);
          transform: translate3d(0px,0px,0px);
  transition-duration: 0.5s;
  margin: 1em 0;
  border: 1px solid #363636; /* Mine Shaft */;
}

.btn:hover {
  background-color: #F5F5F5; /* White Sand */;
  color:  #363636; /* Mine Shaft */;
  -webkit-transform: translate3d(15px,-5px,0px);;
          transform: translate3d(15px,-5px,0px);
  transition: .5s;
}

.align-left {
  text-align: left;
}

.body-set {
  margin: 5% 15%;
}

.body-set.contact{
  text-align: center;
}

/***** 02 Header + Nav Bar *****/

.nav-item.logo {
  max-width: 150px;
  /* justify-content: ; */
  display: inline-block;
}
/*
.nav-item img {
  display: inline;
  max-width: 150px;
}*/

.nav-item a:hover {
  color:  #04071A;
  transition: 0.5s;
}

.values {
  display: -webkit-inline-flex;
  display: inline-flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 2rem;
  -webkit-justify-content: last baseline;
          justify-content: last baseline;
}

.values section {
  -webkit-flex-basis: 300px;
          flex-basis: 300px;
  -webkit-flex-grow: 2;
          flex-grow: 2;
  -webkit-flex-shrink: 2;
          flex-shrink: 2;
  margin: 0% 10% 0% 0%;
}


/*** 05 Sections ***/

.splash-img {
  padding: 5em 10%;
}

.splash-img p {
  margin: 0% 50% 0% 0%

}

.news-block {
  width: 100%;
  padding: 0 0 1em 0;
  margin: 0 0 1em 0;
}

.home-bts-block {
  width: 100%;
  padding: 0 0 0 0;
  margin: 0 0 1em 0;
}

.bts-block {
  width: 32%;
  display: inline-block;
  margin: 0%;
  padding: 0 0 1em 0;
}

.bts-block:hover {
  background-color:  #363636;;
}

.value-block {
  width: 100%;
  display: inline-block;
  margin: 1rem 0;
}

.team-card {
  width: 100%;
  display: inline-block;
  margin: 1rem 0;
}

.production-block {
  width: 100%;
  display: inline-block;
  padding: 0 0 1em 0;
  margin: 0 0 1em 0;
}

.bts-block {
  width: 48%;
  display: inline-block;
  margin: 0;
}

.bts-block a p, .content-section .bts-block h3 {
  text-align: left;
}

.body-section {
  margin: 2rem 0;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.body-section img {
  width: 100%;
}

.content-section {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.body-set h4, .body-set h3, .body-set p {
  text-align: center;
}


.film-info {
  margin: 1em 0;
  display: flexbox;
  -webkit-justify-content: space-between;
          justify-content: space-between
}

.film-info-img {
  display: inline-block;
  width: 100%;
}

.film-info img {
  width: 100%;
  display: inline-block;
}

.film-info iframe {
  position: relative;
  width: 50%;
  height: 500px;
  display: inline-block;
}

.film-info .text-block {
  width: 100%;
  display: inline-block;
  text-align: left;
  vertical-align: top;
}

.film-info h4, .film-info h3, .film-info p {
  text-align: left;
}

img.desktop-img {
  display: none;
}

img.mobile-img {
  display: inline-block;
}

.prod-info {
  margin: 2rem 0;
  width: 100%;
}

.prod-info img {
  width: 100%;
}

.prod-info iframe {
  position: relative;
  width: 50%;
  height: 500px;
  display: inline-block;
}

.prod-info .text-block {
  width: 100%;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin-left: 0em;
}

.prod-info h4, .prod-info h3, .prod-info p {
  text-align: left;
}

.cta {
  text-align: left;
  width: 50%;
  padding: 1em;
  display: inline-block;
  vertical-align: top;  
}

.cta h3, .cta h2 {
  text-align: right;
}

.cta p {
  text-align: left;
}

.movie-header h1 {
  text-align: center;
}

.cast-crew-table {
  text-align: center;
}

.cast-crew-table table {
  width: 60%;
  margin: 0 auto;
  padding: 1.25em;
}

.cast-crew-table th {
  width: 50%;
  padding: 0.5em 1em;
  text-align: left;
  font-family: futura-pt, sans-serif;
}

/*** 06 Index.html ***/

/*** 07 Films ***/
.director {
  font-size: 1rem;
  text-align: center;
}

/***** yy Design Guide Only Code *****/

.color-swatch {
  text-align: left;
  width: 40%;
  max-width: 300px;
  min-width: 200px;
  min-height: 250px;
  display: inline-block;
  padding: 20px 15px;
  margin: 20px 15px;
  border: .5em solid #F3F3F3;
  border-radius: 0px;
  box-shadow: 0px 0px;
  transition: box-shadow .5s, -webkit-transform .5s;
  transition: transform .5s, box-shadow .5s;
  transition: transform .5s, box-shadow .5s, -webkit-transform .5s;
}

.dg-title {
  text-align: left;
  line-height: .9;
}

.dg-lora{
  font-style: normal;
}

.v-Num {
  font-size: 1rem;
  margin-left: 0;
}

.dg-box-set{
  max-width: 100%;
  -webkit-align-content: center;
          align-content: center;
  margin: 0 auto;
  padding: 2rem 5% 4rem 5%;
  overflow: hidden;
}

.musicality-ratio {
  text-align: left;
  padding: 2% 10% 0% 10%;
}

.main-section.design-guide-section {
  padding: 2em 20px 2em 20px;
}

.main-section.disclaimer {
  padding: 10rem 2rem 0.3rem 2rem;
  position: relative;
  left: 2rem;
  margin-right: 2rem;
  margin-top: -10rem;
  margin-bottom: 2rem;
  margin-left: auto;
  z-index: -1;
  max-width: 30rem;
}

.documentaries {
  margin-bottom: 3em;
}

.design-guide-section2 {
  padding: 2em 2%;
  text-align: center;
}

.about-container {
  display: -webkit-flex;
  display: flex;
}

.about-BTS p{
  padding: 1rem;
  margin: 0;
}


sup.h3 {
  transition: 1s;
  display: inline-block;
  -webkit-transform-origin: 50% 37%;
          transform-origin: 50% 37%;
}

sup.h3:hover {
  -webkit-transform: rotate3d(0,0,1,720deg);
          transform: rotate3d(0,0,1,720deg);
  cursor: pointer;
  transition: -webkit-transform 8s ease-in-out;
  transition: transform 8s ease-in-out;
  transition: transform 8s ease-in-out, -webkit-transform 8s ease-in-out;
}

form{
  font-size: 1.25rem;
}

input.text-input{
  font-family: "futura-pt", serif;
  font-size: 1.25rem;
  background-color: #F3F3F3;
  color: #04071A;
  border-radius: 5px;
  padding: .5em;
  margin: 0 2rem 0 0;
  border: 0px solid hsla(0,0%,0%,0);
}

input.radio-input{
  font-family: "futura-pt", serif;
  font-size: 1.25rem;
  background-color: #F3F3F3;
  color: #04071A;
  border-radius: 5px;
  max-width: 60%;
  padding: .5em .5em;
  margin: 0 2rem 0 0;
  border: 0px solid hsla(0,0%,0%,0);
}

textarea{
  font-family: "futura-pt", serif;
  font-size: 1.25rem;
  background-color: #F3F3F3;
  color: #04071A;
  border-radius: 5px;
  padding: .5em 5%;
  margin: 0 2rem 0 0;
  border: 0px solid hsla(0,0%,0%,0);
}

label {
  font-family: "futura-pt", serif;
  font-size: 1.25rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

input.btn {
  background-color: #F3515E;
  color: #F3F3F3;
}

form section {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 1rem 2rem 1rem;
}


form .Message {
  width: 100%;
}

form section.submit-btn {
  margin-bottom: 0;
}

.foot-email {
  word-break: break-word;
}

.word-break {
  word-break: break-word;
}

section.about h1, section.about h2, section.about h3 {
  text-align: center;
}

section.home-section h2 {
  text-align: center;
}

.value-block img {
  width: 100%;
}

.value-block .text-block {
  padding: 0 1rem;
}

.value-block .text-block h3{
  height: 2rem;
}

section.body-section.about h2 {
  text-align: center;
}

.about p.Collaborators-blurb {
  text-align: center;
  padding: 0 0%;
  
}

.grid-container {
  display: grid;
  grid-template: 60% 10% 30% / repeat(5, 18.5%);
  grid-column-gap: 1.875%;
}

/***** 07 Case Studies *****/

.case-study p {
  margin: 1.5em 0;
}

.case-study section.about {
  margin-right: 15%;
}

.case-study section.challenge {
  margin-left: 15%;
  position: relative;
  margin-top: -5rem;
  padding-top: 6rem;
  z-index: -1;
}

.cta-case-study {
  margin: 2em 2%;
}

.cta-case-study h3 {
  margin-bottom: 1.5em;
}

/***** 08 Media Queries *****/

@media only screen and (min-width: 600px) {
  input {
    max-width: 100%;
  }
  .main-nav-bar {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .main-nav-bar .header-nav {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .main-nav-bar .header-nav li{
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .header-nav .nav-item.first{
    padding-top: .5rem;
  }

  .header-nav .nav-item.last {
    padding-right: 0rem;
  }

  div.past-clients {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: row;
            flex-direction: row;
    justify-items: center;
  }

  img.past-clients-img {
    width: 40%;
    height: 40%;
    margin: 0 auto;
  }

}

@media only screen and (min-width: 960px) {
/*  html {
    font-size: 20px;
  }*/

  form{
    width: 100%;
    font-size: 1.563rem;
  } 
    
  .main-section {
    margin: 2em 0%;
  }

  .main-section.design-guide-section {
    padding: 2em 20px 2em 20px;
  }

  .main-section.disclaimer {
    padding: 12rem 2rem 1rem 2rem;
    margin-top: -14rem;
  }

  .box-set{
    padding: 2rem 10% 4rem 10%;
    max-width: 1600px;
  }

  .musicality-ratio {
      text-align: left;
  }

  h1 {
      font-size: 5.492rem; /* ratio is major fifth from next lowest */
  }
    
  h2 {
      font-size: 2.441rem; /* ratio is major 3rd from next lowest */
  }
    
  h3 {
    font-size: 1.563rem; /* ratio is major 3rd from next lowest */
  }
  
  h4 {
    font-size: 1rem; /* base of "chord" */
  }
  
  h5 {
    font-size: 0.75rem; /* ratio is perfect 4th smaller from base */
  }
  
  h6 {
    font-size: 0.6rem; /* ratio is major 3rd smaller from h5 */
  }  

  .h3 {
    font-size: 1.563rem; /* ratio is major 3rd from next lowest */
    font-family: "Quicksand", sans-serif; 
  }

  .h4 {
    font-size: 1rem;
  }

  .v-Num {
    font-size: 1.563rem;
    margin-left: 0;
  }

  form{
    font-size: 1.563rem;
  }

  input {
    max-width: 100%;
  }
  
  footer {
   padding: 3em 10%;
  }

  .foot-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }

  .case-study section.challenge {
    padding-top: 4rem;
  
  }

  .about p.Collaborators-blurb {
    text-align: center;
    padding: 0 30%;
    
  }

  .prod-info {
    margin: 3rem 0;
  }

  .prod-info img {
    width: 50%;
    margin-right: 1rem;
  }
  
  .prod-info.odd img {
    width: 50%;
    margin-left: 1rem;
  }
  
  .prod-info .text-block {
    width: 40%;
  }

  .content-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
  }


  .production-block {
    width: 18%;
    display: inline-block;
    padding: 0 0 1em 0;
  }

  .news-block {
    width: 32%;
    display: inline-block;
    margin: 0%;
    padding: 0 0 1em 0;
  }


  .home-bts-block {
    width: 49%;
    display: inline-block;
    margin: 0%;
    padding: 0 0 0 0;
  }

  .film-info{ 
    display: -webkit-flex; 
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
  }

  .film-info .text-block {
    width: 48%;
    display: inline-block;
  }

  .film-info-img {
    width: 48%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .film-info-img-stack {
    width: 48%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .film-info-img img {
      width: 100%;
      display: inline-block;
  }

  .film-info-img-stack .stacked-top{
    width: 60%;
    display: inline-block;
    z-index: 2;
    max-width: 360px;
  }

  .film-info-img-stack .stacked-top-2{
    width: 100%;
    display: inline-block;
    z-index: 2;
    max-width: 600px;
  }

  .film-info-img-stack .stacked-bottom{
    width: 100%;
    position: relative;
    top: -1em;
    z-index: 1;
    max-width: 600px;
  }

  .film-info-img-stack .stacked-bottom-2{
    width: 70%;
    position: relative;
    top: -1em;
    z-index: 3;
    max-width: 420px;
  }

  .film-info2 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
  }

  .filmimg-2 {
    width: 48%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .filmimg-2 img {
    width: 100%;
  }

  .textbox-2 {
    width: 50%;
    display: inline-block;
  }

  img.desktop-img {
    display: inline-block;
  }
  
  img.mobile-img {
    display: none;
  }

  .value-block {
    width: 32%;
    display: inline-block;
    margin: 1rem 0;
  }

  .about-BTS {
    margin:0 30%; 
  }

  .team-card {
    width: 49%;
    display: inline-block;
    margin: 0%;
  }

}

@media only screen and (min-width: 1440px) {
  html {
    font-size: 24px;
  }
  

  .odd-client {
    padding-left: 50%;
    text-align: right;
   }
  
   .form-section {
     width: 60%;
     margin: 0 20%; 
   }

   form {
     width: 100%;
   }

  img.past-clients-img {
    width: 20%;
    height: 20%;
  }
}

/***** zz Old Code *****/

/* Old Form Submission - by Alex 
.w-form {
  margin: 0 0 15px;
}

.w-form-done {
  display: none;
  padding: 20px;
  text-align: center;
  background-color: #dddddd;
}

.w-form-fail {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede;
}
*****/
/* 
div.box-set.swatch {

} 
*/
